import { Icon } from '@nrk/yr-icons';
import { Text } from '../Text/Text';
import './MapBetaLabel.scss';

interface IProps {
  url: string;
}

export function MapBetaLabel(props: IProps) {
  const { url } = props;

  return (
    <a className="map-beta-label" href={url} target="_blank" rel="noreferrer">
      <span className="map-beta-label__content">
        <Text size="4">Beta</Text> <Icon id="icon-info" size={2} />
      </span>
    </a>
  );
}
