import { Text } from '../Text/Text';
import './MapAttribution.scss';

export function MapAttribution() {
  return (
    <Text
      as="a"
      className="map-attribution"
      href="https://www.openstreetmap.org/copyright"
      target="_blank"
      rel="noreferrer"
      size="6"
    >
      © OpenStreetMap
    </Text>
  );
}
