// Design system colors from Figma
// See https://www.figma.com/file/QYLlyWT77kBkp8pUJpwTZg/Yr-Colors?node-id=0%3A1

// Base colors
export const BASE_COLOR_BLACK = '#21292b';
export const BASE_COLOR_GREY = '#56616c';
export const BASE_COLOR_BLUE = '#006edb';
export const BASE_COLOR_RED = '#c60000';
export const BASE_COLOR_GREEN = '#457222';
export const BASE_COLOR_BORDER_GREY = '#c3d0d8';
export const BASE_COLOR_WHITE = '#ffffff';
export const BASE_COLOR_COAL = '#171F28';

// Custom colors
export const CUSTOM_COLOR_YR_BLUE = '#00b8f1';
export const CUSTOM_COLOR_GREEN = '#5dca0a';
export const CUSTOM_COLOR_ORANGE = '#ff9d00';
export const CUSTOM_COLOR_YELLOW = '#ffe600';
export const CUSTOM_COLOR_PURPLE = '#aa00f2';
export const CUSTOM_COLOR_SNOWY_GREY = '#2d5f73';
export const CUSTOM_COLOR_WARNING_YELLOW_BORDER = '#938616';
export const CUSTOM_COLOR_WARNING_ORANGE_BORDER = '#9a6100';
export const CUSTOM_COLOR_WARNING_RED_BORDER = '#721515';
export const CUSTOM_COLOR_ASTRONOMICAL = '#043551';
export const CUSTOM_COLOR_NAUTICAL = '#075078';
export const CUSTOM_COLOR_CIVIL = '#106ca0';
export const CUSTOM_COLOR_PURPLE_DARK = '#3c185a';

// Lightened colors
export const LIGHTENED_COLOR_BLUE = '#cce2f8';
export const LIGHTENED_COLOR_RED = '#e5c1c2';
export const LIGHTENED_COLOR_ORANGE = '#f2d8a9';
export const LIGHTENED_COLOR_YELLOW = '#f2eeb5';
export const LIGHTENED_COLOR_GREEN = '#d0e9c4';
export const LIGHTENED_COLOR_PURPLE = '#e0c1f2';
export const LIGHTENED_COLOR_SNOWY_GREY = '#d5dfe3';

// Faded
export const FADED_COLOR_BLUE = '#eef6f8';
export const FADED_COLOR_RED = '#fcf2f2';
export const FADED_COLOR_ORANGE = '#fff9f2';
export const FADED_COLOR_YELLOW = '#fffdf1';
export const FADED_COLOR_GREEN = '#edf5e7';
export const FADED_COLOR_PURPLE = '#f3e9f8';
export const FADED_COLOR_GREY = '#edf1f2';
