import settings from '../../app/settings';
import { getLocalStorageObject, isLocalStorageSupported, setLocalStorageObject } from './localStorage';
const MAX_COUNT = 3;

interface ICountStorageObject {
  currentCount: number;
  snackbarClosedCount?: number;
}

export const MAP_TYPE_WEATHER_COUNT_STORAGE_ID = 'mapTypeWeather';
export const MAP_TYPE_SELECTOR_COUNT_STORAGE_ID = 'mapTypeSelector';

export function incrementCountStorage(countStorageId: string) {
  if (isLocalStorageSupported()) {
    let previousObject = getLocalStorageObject<ICountStorageObject>(countStorageId) ?? { currentCount: 0 };

    // TODO(as) [2023-08-30]:snackbarClosedCount was renamed to currentCount to make this storage more generic, should be safe to remove after a few months.
    if (previousObject.snackbarClosedCount != null) {
      previousObject.currentCount = previousObject.snackbarClosedCount;
      previousObject.snackbarClosedCount = undefined;
    }

    if (previousObject.currentCount < MAX_COUNT) {
      const newObject = { currentCount: previousObject.currentCount + 1 };
      setLocalStorageObject(countStorageId, newObject);
    }
  }
}

export function countStorageHasReachedMaxCount(countStorageId: string) {
  // Don't show snackbar when running browser tests
  if (settings.site.isBrowserTest) {
    return false;
  }

  // If local storage is not supported we don't want to show a snack to the user
  // because they might be unable to close it and save that information
  // back to local storage.
  if (isLocalStorageSupported() === false) {
    return false;
  }

  let previousObject = getLocalStorageObject<ICountStorageObject>(countStorageId) ?? { currentCount: 0 };

  // TODO(as) [2023-08-30]:snackbarClosedCount was renamed to currentCount to make this storage more generic, should be safe to remove after a few months.
  if (previousObject.snackbarClosedCount != null) {
    previousObject.currentCount = previousObject.snackbarClosedCount;
    previousObject.snackbarClosedCount = undefined;
  }

  return MAX_COUNT <= previousObject.currentCount;
}
