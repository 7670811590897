import { IMapEvent, YrMap } from '@nrk/yr-map';
import { useEffect, useState } from 'react';
import { track } from '../../lib/analytics/track';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { IconButton } from '../IconButton/IconButton';
import './MapZoomToolbar.scss';

interface IProps {
  map: YrMap;
}

export function MapZoomToolbar(props: IProps) {
  const { map } = props;

  const translate = useTranslate();

  const [isMinZoom, setIsMinZoom] = useState(false);
  const [isMaxZoom, setIsMaxZoom] = useState(false);

  useEffect(() => {
    function handleMapEvent(event: IMapEvent) {
      if (event.type === 'map:zoom') {
        setIsMinZoom(event.currentZoom <= event.minZoom);
        setIsMaxZoom(event.currentZoom >= event.maxZoom);
      }
    }

    map.addEventListener(handleMapEvent);

    return () => {
      map.removeEventListener(handleMapEvent);
    };
  }, [map]);

  return (
    <div className="map-zoom-toolbar">
      <IconButton
        className="map-zoom-toolbar__button"
        as="button"
        buttonSize="large"
        buttonType="primary"
        hideBorder={true}
        icon="icon-zoom-in"
        ariaLabel={translate('mapPage/zoomToolbar/zoomIn')}
        onClick={() => {
          track.event({ category: 'map', action: 'zoom_in' });

          map.zoomIn();
        }}
        disabled={isMaxZoom}
      />
      <IconButton
        className="map-zoom-toolbar__button"
        as="button"
        buttonSize="large"
        buttonType="primary"
        hideBorder={true}
        icon="icon-zoom-out"
        ariaLabel={translate('mapPage/zoomToolbar/zoomOut')}
        onClick={() => {
          track.event({ category: 'map', action: 'zoom_out' });

          map.zoomOut();
        }}
        disabled={isMinZoom}
      />
    </div>
  );
}
