import { Icon } from '@nrk/yr-icons';
import { createPageUrl } from '../../app/redirects';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Text } from '../Text/Text';
import './MapFooter.scss';

interface IProps {
  mapType?: string;
  locationId?: string;
}

export function MapFooter(props: IProps) {
  const { mapType, locationId } = props;
  const translate = useTranslate();
  const localeCode = useLocaleCode();

  const mapTypeString = translate(`map/footer/type/${mapType}`);

  const href =
    locationId != null
      ? createPageUrl({
          localeCode,
          pageId: 'map',
          subpageId: mapType,
          locationId
        })
      : createPageUrl({
          localeCode,
          pageId: 'map',
          subpageId: mapType
        });

  const link = {
    text: translate('map/footer/link', { mapType: mapTypeString.toLowerCase() }),
    url: `https://www.yr.no${href}`
  };

  return (
    <div className="map-footer">
      <div className="map-footer__attribute">
        <Icon id="icon-logo-yr" className="map-footer__attribute-yr-logo" size={4} />
        <Text className="map-footer__attribute-map-type" size="4">
          {translate('map/footer/attribution', { mapType: mapTypeString })}
        </Text>
      </div>
      <div className="map-footer__link-container">
        <Text size="4" isLink={true}>
          {/* Because we are using internal routes when we are routing inside an iframe in nrk articles,
          we need to use JavaScript so that it opens in a new tab. */}
          <a
            className="map-footer__link"
            onClick={event => {
              event.preventDefault();

              onClick(link.url);
            }}
            href={link.url}
          >
            {link.text}
            <Icon className="map-footer__link-icon" id="icon-new-window" size={2} />
          </a>
        </Text>
      </div>
    </div>
  );
}

function onClick(href: string) {
  window.open(href, '_blank')?.focus();
}
