import { TYPOGRAPHY_FONT_WEIGHT_BOLD } from '@nrk/yr-design-tokens';
import {
  getDefaultStyle,
  getDefaultWithNameStyle,
  getHeatmapStyle,
  IMapEvent,
  IMapStyle,
  IPlainBounds,
  YrMap
} from '@nrk/yr-map';
import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { useFetchLocation } from '../../data/locations/hooks';
import { captureMessage } from '../../lib/errorReporter';
import { isWebglSupported } from '../../lib/helpers/webgl';
import { useCurrentLocationId } from '../../lib/hooks';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useSupportsWebp } from '../../lib/hooks/useSupportsWebp';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { FONT_FAMILY } from '../../styles/main/_global';
import { BASE_COLOR_BLACK, BASE_COLOR_BLUE, BASE_COLOR_RED } from '../../styles/yr/variables/_colors';
import { MapAttribution } from '../MapAttribution/MapAttribution';
import { MapBetaLabel } from '../MapBetaLabel/MapBetaLabel';
import { MapFooter } from '../MapFooter/MapFooter';
import { MapIconButton } from '../MapIconButton/MapIconButton';
import MapTypeSelector, { TMapTypeSelectorType } from '../MapLayerSelector/MapTypeSelector';
import { MapScaleLine } from '../MapScaleLine/MapScaleLine';
import { MapZoomToolbar } from '../MapZoomToolbar/MapZoomToolbar';
import './Map.scss';

export const MAP_PORTAL_INFO_BUTTON_ID = 'map-portal-info-button';
export const MAP_PORTAL_TYPE_TOOLBAR_ID = 'map-portal-type-toolbar';
export const MAP_PORTAL_LEGEND_ID = 'map-portal-wind-legend';
export const MAP_PORTAL_A11Y_ID = 'map-portal-a11y-button';
export const MAP_PORTAL_SNACKBAR_ID = 'map-portal-snackbar';

const PRIMARY_MARKER_CLASS_NAMES = {
  parent: 'map__primary-marker',
  pin: 'map__primary-marker-pin'
};

const SECONDARY_MARKER_CLASS_NAMES = {
  parent: 'map__secondary-marker',
  pin: 'map__secondary-marker-pin'
};

interface IProps {
  bounds?: IPlainBounds;
  betaUrl?: string;
  cursor?: 'arrow' | 'default';
  rounded?: boolean;
  useCooperativeGesturesOnTouchDevices?: {
    value: boolean;
    helpText?: string;
  };
  shouldRenderMapInMap?: boolean;
  shouldShowLegend?: boolean;
  shouldShowFullScreenButton?: boolean;
  shouldShowZoomToolbar?: boolean;
  shouldShowInfoButton?: boolean;
  shouldShowMapTypeSelector?: boolean;
  mapTypeSelector?: {
    currentMapType: TMapTypeSelectorType;
    mapTypes: {
      weather: boolean;
      radar: boolean;
      wind: boolean;
      lightning: boolean;
      temperature: boolean;
    };
  };
  render: (params: {
    map: YrMap;
    mapInMapElement: HTMLDivElement | null;
    setAriaLabel: (label: string) => void;
    styles: {
      default: IMapStyle;
      defaultWithName: IMapStyle;
      heatmap: IMapStyle;
    };
    onChangeMapType: () => void;
  }) => React.ReactNode;
}

export function Map(props: IProps) {
  const {
    bounds,
    betaUrl,
    cursor = 'default',
    rounded = false,
    shouldRenderMapInMap = false,
    shouldShowLegend = true,
    shouldShowFullScreenButton = true,
    shouldShowZoomToolbar = true,
    shouldShowInfoButton = true,
    shouldShowMapTypeSelector = true,
    mapTypeSelector,
    useCooperativeGesturesOnTouchDevices,
    render
  } = props;

  const { isFirstRender, currentPage, currentPageSettings, setCurrentPageSettings } = useAppState();
  const { subpageId } = currentPage.details.params;
  const { embedded } = currentPage.details.query;
  const localeCode = useLocaleCode();
  const translate = useTranslate();
  const locationId = useCurrentLocationId();
  const { data: location } = useFetchLocation({ locationId });
  const [ariaLabel, setAriaLabel] = useState<string>();
  const [pageSettingsInitialized, setPageSettingsInitialized] = useState(false);
  const [hasLostContext, setHasLostContext] = useState(false);
  const [supportsWebGL, setSupportsWebGL] = useState(true);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [map, setMap] = useState<YrMap>();
  const mapElementRef = useRef<HTMLDivElement>(null);
  const mapInMapElementRef = useRef<HTMLDivElement>(null);
  const supportsWebp = useSupportsWebp();

  // We do not want to continue making context so only run when initializing
  useEffect(() => {
    if (isWebglSupported() === false) {
      setSupportsWebGL(false);
    }
  }, []);

  // We need to clean up and release all internal resources associated with this map when navigating away,
  // this includes DOM elements, event bindings, web workers, and WebGL resources.
  useEffect(() => {
    return () => {
      if (map != null) {
        map.maplibregl.remove();
      }
    };
  }, [map]);

  const styles = useMemo(() => {
    let languagePriority;
    if (localeCode === 'en') {
      languagePriority = ['en', 'latin'];
    } else if (localeCode === 'sme') {
      languagePriority = ['sme', 'no_override', 'no', 'latin'];
    } else {
      languagePriority = ['no_override', 'no', 'latin'];
    }

    return {
      default: getDefaultStyle(languagePriority),
      defaultWithName: getDefaultWithNameStyle(languagePriority),
      heatmap: getHeatmapStyle(languagePriority)
    };
  }, [localeCode]);

  const { showHeader } = currentPageSettings;

  // Preserve showHeader when navigating to a new subpage
  // Triggered by onChangeMapType
  useEffect(() => {
    if (pageSettingsInitialized === false) {
      setCurrentPageSettings({ ...currentPageSettings, showHeader: showHeader });
    }

    setPageSettingsInitialized(true);
  }, [pageSettingsInitialized, currentPageSettings, showHeader, setCurrentPageSettings]);

  // Handle dragevent
  useEffect(() => {
    if (map == null) {
      return;
    }

    function handleDragStart() {
      setIsDragging(true);
    }

    function handleDragEnd() {
      setIsDragging(false);
    }

    map.maplibregl.on('dragstart', handleDragStart);
    map.maplibregl.on('dragend', handleDragEnd);

    return () => {
      map.maplibregl.off('dragstart', handleDragStart);
      map.maplibregl.off('dragend', handleDragEnd);
    };
  }, [map, setIsDragging]);

  // This is available in the render prop, should be called when changing map type
  // Currently used for preserving showHeader setting
  function onChangeMapType() {
    setPageSettingsInitialized(false);
  }

  // Initialize the map
  useEffect(() => {
    if (mapElementRef.current == null) {
      return;
    }

    if (locationId != null && location == null) {
      return;
    }

    if (supportsWebp == null) {
      return;
    }

    if (map == null) {
      const map = new YrMap({
        localeCode,
        center: location?.position,
        bounds,
        mapElement: mapElementRef.current,
        supportsWebp,
        showDefaultAttribution: false,
        useCooperativeGesturesOnTouchDevices,
        // We dont want the map to be interactive in an iframe in an article at nrk.no.
        // If the embedded query parameter is used, we want to disable all interactions in the map.
        interactive: !embedded,
        theme: {
          font: {
            family: FONT_FAMILY,
            weight: TYPOGRAPHY_FONT_WEIGHT_BOLD.toString()
          },
          colors: {
            temperatureCold: BASE_COLOR_BLUE,
            temperatureWarm: BASE_COLOR_RED,
            wind: BASE_COLOR_BLACK
          }
        },
        units: { distance: 'metric', wind: 'mps', temperature: 'celsius' },
        assets: {
          weatherSymbolsPath: '/assets/images/weather-symbols/light-mode/shadows/svg',
          pin: {
            iconUrl: '/assets/images/map/marker-primary.png',
            shadowUrl: '/assets/images/map/marker-primary-shadow.png',
            hoverUrl: '/assets/images/map/marker-primary-hover.png'
          },
          secondaryPin: {
            iconUrl: '/assets/images/map/marker-secondary.png'
          }
        },
        classNames: {
          pin: PRIMARY_MARKER_CLASS_NAMES,
          secondaryPin: SECONDARY_MARKER_CLASS_NAMES
        }
      });

      map.addStyle(styles.default);
      map.addStyle(styles.defaultWithName);
      map.addStyle(styles.heatmap);

      setMap(map);
    }
  }, [
    isFirstRender,
    useCooperativeGesturesOnTouchDevices,
    map,
    mapElementRef,
    styles,
    location,
    localeCode,
    supportsWebp,
    bounds,
    locationId,
    embedded
  ]);

  // Trigger a map resize whenever the header is toggled by the resize button
  useEffect(() => {
    if (map == null) {
      return;
    }

    map.maplibregl.resize();
  }, [map, currentPageSettings.showHeader]);

  useEffect(() => {
    if (map != null) {
      map.addEventListener((event: IMapEvent) => {
        if (event.type === 'map:webgl-context-lost') {
          setHasLostContext(true);
        }
      });
    }
  }, [map]);

  // Don't render anything on the server. Users with no JS support will see the no JS banner.
  if (isFirstRender === true) {
    return null;
  }

  // Show an error if WebGL is not supported
  if (supportsWebGL === false) {
    return <p className="map__error">{translate('mapPage/fallback')}</p>;
  }

  // Show error if webgl context is lost, since this is not handled properly by maplibre
  // https://github.com/mapbox/mapbox-gl-js/issues/9516
  if (hasLostContext === true) {
    if (Math.random() * 100 === 0) {
      captureMessage('WebGLContextLost', {});
    }
    return (
      <p className="map__error">
        {translate('notifications/mapError/title')} {translate('notifications/precipitationMapError/text')}
      </p>
    );
  }

  // We do not want to show any scrollbar when nrk is embedding our maps in their articles.
  // Because nrk.no are supporting old browsers we cannot use css has selector and need to set overflow like this.
  if (embedded === true) {
    document.documentElement.style.overflowY = 'hidden';
  }

  return (
    <div
      className={classNames('map', { 'map--rounded': rounded })}
      aria-label={ariaLabel}
      role="img"
      data-cursor={cursor}
      data-is-dragging={isDragging}
      data-map-type={subpageId}
      data-embedded={embedded}
    >
      {map && (
        <div className="map__layout">
          {embedded === false && (
            <div className="map__scale-line" data-has-beta-label={betaUrl != null}>
              <MapScaleLine map={map} />
            </div>
          )}

          {betaUrl != null && (
            <div className="map__beta-label">
              <MapBetaLabel url={betaUrl} />
            </div>
          )}

          {shouldShowLegend && (
            <div
              className="map__portal"
              data-type="legend"
              data-has-beta-label={betaUrl != null}
              id={MAP_PORTAL_LEGEND_ID}
            />
          )}
          {shouldShowFullScreenButton && (
            <div className="map__fullscreen-button">
              <MapIconButton
                iconId={showHeader ? 'icon-expand' : 'icon-collapse'}
                ariaLabel={showHeader ? translate('grammar/maximize') : translate('grammar/minimize')}
                onClick={() => {
                  setCurrentPageSettings({ ...currentPageSettings, showHeader: !showHeader });
                }}
              />
            </div>
          )}

          {shouldShowZoomToolbar && (
            <div className="map__zoom-toolbar">
              <MapZoomToolbar map={map} />
            </div>
          )}

          {embedded === false && <div className="map__portal" data-type="a11y" id={MAP_PORTAL_A11Y_ID} />}

          <div className="map__bottom">
            {embedded === false && (
              <div className="map__portal" data-type="type-snackbar" id={MAP_PORTAL_SNACKBAR_ID} />
            )}
            <div className="map__info-and-selector">
              {shouldShowInfoButton && (
                <div className="map__portal" data-type="info-button" id={MAP_PORTAL_INFO_BUTTON_ID} />
              )}
              {mapTypeSelector != null && shouldShowMapTypeSelector && (
                <div className="map__type-selector">
                  <MapTypeSelector
                    currentMapType={mapTypeSelector.currentMapType}
                    mapTypes={mapTypeSelector.mapTypes}
                    location={location}
                  />
                </div>
              )}
            </div>

            {embedded === false && (
              <div className="map__attribution">
                <MapAttribution />
              </div>
            )}

            <div className="map__portal" data-type="type-toolbar" id={MAP_PORTAL_TYPE_TOOLBAR_ID} />

            {embedded === true && (
              <div className="map__footer">
                <MapFooter mapType={subpageId} locationId={locationId} />
              </div>
            )}
          </div>

          {embedded === true && (
            <div className="map__attribution">
              <MapAttribution />
            </div>
          )}
        </div>
      )}

      {shouldRenderMapInMap === true && (
        <div
          className={classNames('map__map-in-map', { 'map__map-in-map--rounded': rounded })}
          data-type="map-in-map"
          ref={mapInMapElementRef}
        />
      )}
      <div className="map__map" ref={mapElementRef} />
      {map != null &&
        render({ map, setAriaLabel, onChangeMapType, styles, mapInMapElement: mapInMapElementRef.current })}
    </div>
  );
}
