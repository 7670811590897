import { autoUpdate, offset, useFloating } from '@floating-ui/react-dom';
import CoreToggle from '@nrk/core-toggle/jsx';
import { TIconId } from '@nrk/yr-icons';
import { useEffect, useRef, useState } from 'react';
import { createPageUrl } from '../../app/redirects';
import { track } from '../../lib/analytics/track';
import { setLocalStorageObject } from '../../lib/helpers/localStorage';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useMediaQueryState } from '../../lib/hooks/useMediaQueryState';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ILocation } from '../../model/location';
import { CloseButton } from '../CloseButton/CloseButton';
import { MapTypeButton } from '../MapTypeButton/MapTypeButton';
import './MapTypeSelector.scss';
import MapTypeSelector__TypeButton from './MapTypeSelector__TypeButton';
import {
  MAP_TYPE_SELECTOR_COUNT_STORAGE_ID,
  countStorageHasReachedMaxCount,
  incrementCountStorage
} from '../../lib/helpers/countStorage';
import { Heading } from '../Heading/Heading';

export type TMapTypeSelectorType = 'weather' | 'radar' | 'wind' | 'lightning' | 'temperature';

// Minimum width for when we auto expand the map type selector need to be high enough to avoid it overlapping the map toolbar
const MAP_TYPE_SELECTOR_AUTO_EXPAND_MIN_WIDTH = 1525;

export interface ILocalStorageMenu {
  lastViewedMapType: string;
}

interface IProps {
  currentMapType: TMapTypeSelectorType;
  mapTypes: {
    weather: boolean;
    radar: boolean;
    wind: boolean;
    lightning: boolean;
    temperature: boolean;
  };
  location?: ILocation;
}

const mapTypeIcons: Record<TMapTypeSelectorType, TIconId> = {
  weather: 'icon-partly-cloudy',
  radar: 'icon-umbrella-open',
  wind: 'icon-wind',
  lightning: 'icon-lightning',
  temperature: 'icon-temperature'
};

export default function MapTypeSelector(props: IProps) {
  const { currentMapType, mapTypes, location } = props;

  // Media query that returns true if the viewport width is wide enough so that we should auto expand the map type selector
  const showMapTypeSelectorMediaQuery = useMediaQueryState({
    mediaQuery: `(min-width: ${MAP_TYPE_SELECTOR_AUTO_EXPAND_MIN_WIDTH}px)`,

    // Default to false so we don't auto expand the map type selector before the media query has run.
    defaultValue: false
  });

  useEffect(() => {
    // We want to increment the count for how many times the map type selector has been shown each time this components mounts
    incrementCountStorage(MAP_TYPE_SELECTOR_COUNT_STORAGE_ID);
  }, []);

  const [active, setActive] = useState(showMapTypeSelectorMediaQuery);
  // We don't want to automatically expand the map type selector if a user has actively closed it and then changes the viewport size
  // To avoid that we keep track of if the user has interacted with the map type selector or not
  const [hasInteractedWithMapTypeSelector, setHasInteractedWithMapTypeSelector] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const { x, y, reference, floating, strategy, update } = useFloating({
    placement: 'top-end',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [offset(10)]
  });

  useEffect(() => {
    const shouldShowMapTypeSelector =
      showMapTypeSelectorMediaQuery &&
      countStorageHasReachedMaxCount(MAP_TYPE_SELECTOR_COUNT_STORAGE_ID) === false &&
      hasInteractedWithMapTypeSelector === false;

    // To avoid automatically closing the map type selector we only update the state to expand the selector, not to close it.
    if (shouldShowMapTypeSelector) {
      setActive(shouldShowMapTypeSelector);
    }
    // If the map type selector is automatically expanded we need to update the position to be sure it is positioned correctly
    update();
  }, [showMapTypeSelectorMediaQuery, hasInteractedWithMapTypeSelector, update]);

  const localeCode = useLocaleCode();
  const translate = useTranslate();

  // Close map type selector if the user clicks on the root element,
  // effectively this means they clicked the backdrop.
  function handleMapTypeSelectorClick(event: React.MouseEvent) {
    if (event.target === rootRef.current) {
      setActive(false);
    }
  }

  const mapTypeLabels = {
    weather: translate('map/mapTypeLabel/weather'),
    radar: translate('map/mapTypeLabel/precipitation'),
    wind: translate('map/mapTypeLabel/wind'),
    lightning: translate('map/mapTypeLabel/lightning'),
    temperature: translate('map/mapTypeLabel/temperature')
  };

  const radarURL = createPageUrl({
    localeCode,
    pageId: 'map',
    subpageId: 'radar',
    locationId: location?.id,
    urlPath: location?.urlPath
  });

  const weatherURL = createPageUrl({
    localeCode,
    pageId: 'map',
    subpageId: 'weather',
    locationId: location?.id,
    urlPath: location?.urlPath
  });

  const windURL = createPageUrl({
    localeCode,
    pageId: 'map',
    subpageId: 'wind',
    locationId: location?.id,
    urlPath: location?.urlPath
  });

  const lightningURL = createPageUrl({
    localeCode,
    pageId: 'map',
    subpageId: 'lightning',
    locationId: location?.id,
    urlPath: location?.urlPath
  });

  const temperatureURL = createPageUrl({
    localeCode,
    pageId: 'map',
    subpageId: 'temperature',
    locationId: location?.id,
    urlPath: location?.urlPath
  });

  return (
    // The `onClick` even handler is only used as an additional way to close the map layer selector
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="map-type-selector" data-active={active} ref={rootRef} onClick={handleMapTypeSelectorClick}>
      <MapTypeButton
        ref={reference}
        iconId={mapTypeIcons[currentMapType]}
        label={mapTypeLabels[currentMapType]}
        onClick={() => (active === false ? track.event({ category: 'map', action: 'open_map_selector' }) : null)}
      />
      <CoreToggle
        hidden={active === false}
        onToggle={event => {
          // Reflect the `hidden` attribute to our local variable.
          // The CoreToggle component is not a controlled component
          // and will set itself to hidden when e.g. the user presses escape.
          // We want our local variable to match CoreToggle's `hidden` attribute
          // because CoreToggle does listen for when the attribute changes
          // which means we can close CoreToggle by changing `hidden` from `true` to `false`,
          // but in order to do that we need to keep the local variable in sync.
          setActive(!event.target.hidden);
        }}
      >
        <div
          ref={floating}
          className="map-type-selector__dialog"
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            width: 'max-content'
          }}
        >
          <div className="map-type-selector__dialog-header-row">
            <Heading level="h1" size="4">
              {translate('mapTypeSelector/chooseMap')}
            </Heading>
            <CloseButton
              as="button"
              onClick={() => {
                setHasInteractedWithMapTypeSelector(true);
                setActive(false);
              }}
            />
          </div>

          <div className="map-type-selector__dialog-image-row">
            {mapTypes.weather && (
              <MapTypeSelector__TypeButton
                imageSrc={'/assets/images/map/thumbnail-weather.png'}
                label={mapTypeLabels.weather}
                href={weatherURL}
                alt={translate('mapPage/types/weather/name')}
                selected={currentMapType === 'weather'}
                onSelected={() => {
                  setActive(false);
                  setHasInteractedWithMapTypeSelector(true);
                  setLocalStorageMenu({ lastViewedMapType: 'weather' });
                }}
              />
            )}

            {mapTypes.radar && (
              <MapTypeSelector__TypeButton
                imageSrc={'/assets/images/map/thumbnail-radar.png'}
                label={mapTypeLabels.radar}
                href={radarURL}
                alt={translate('mapPage/types/radar/name')}
                selected={currentMapType === 'radar'}
                onSelected={() => {
                  setActive(false);
                  setHasInteractedWithMapTypeSelector(true);
                  setLocalStorageMenu({ lastViewedMapType: 'radar' });
                }}
              />
            )}

            {mapTypes.wind && (
              <MapTypeSelector__TypeButton
                imageSrc={'/assets/images/map/thumbnail-wind.png'}
                label={mapTypeLabels.wind}
                href={windURL}
                alt={translate('mapPage/types/wind/name')}
                selected={currentMapType === 'wind'}
                onSelected={() => {
                  setActive(false);
                  setHasInteractedWithMapTypeSelector(true);
                  setLocalStorageMenu({ lastViewedMapType: 'wind' });
                }}
              />
            )}

            {mapTypes.lightning && (
              <MapTypeSelector__TypeButton
                imageSrc={'/assets/images/map/thumbnail-lightning.png'}
                label={mapTypeLabels.lightning}
                href={lightningURL}
                alt={translate('mapPage/types/lightning/name')}
                selected={currentMapType === 'lightning'}
                onSelected={() => {
                  setActive(false);
                  setHasInteractedWithMapTypeSelector(true);
                  setLocalStorageMenu({ lastViewedMapType: 'lightning' });
                }}
              />
            )}

            {mapTypes.temperature && (
              <MapTypeSelector__TypeButton
                imageSrc={'/assets/images/map/thumbnail-temperature.png'}
                label={mapTypeLabels.temperature}
                href={temperatureURL}
                alt={translate('mapPage/types/temperature/name')}
                selected={currentMapType === 'temperature'}
                onSelected={() => {
                  setActive(false);
                  setHasInteractedWithMapTypeSelector(true);
                  setLocalStorageMenu({ lastViewedMapType: 'temperature' });
                }}
              />
            )}
          </div>
        </div>
      </CoreToggle>
    </div>
  );
}

function setLocalStorageMenu(menu: ILocalStorageMenu) {
  setLocalStorageObject('menu', menu);
}
