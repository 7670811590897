import { TIconId } from '@nrk/yr-icons';
import { forwardRef } from 'react';
import { Button } from '../Button/Button';
import './MapTypeButton.scss';

interface IProps {
  iconId: TIconId;
  label: string;
  onClick?: () => void;
}

export const MapTypeButton = forwardRef<HTMLButtonElement, IProps>((props, ref) => {
  const { iconId, label, onClick } = props;

  return (
    <Button
      className="map-type-button"
      as="button"
      ref={ref}
      onClick={onClick}
      iconBefore={iconId}
      iconAfter={'icon-chevron-down'}
      buttonSize="large"
      buttonShape="rounded"
      hideBorder={true}
    >
      {label}
    </Button>
  );
});
