import { testSupportsWebp } from '@nrk/yr-map';
import { useEffect, useState } from 'react';

export function useSupportsWebp() {
  const [supportsWebp, setSupportsWebp] = useState<boolean | undefined>();

  useEffect(() => {
    const abortController = new AbortController();

    async function test() {
      const result = await testSupportsWebp();

      if (abortController.signal.aborted === false) {
        setSupportsWebp(result);
      }
    }

    test();

    return () => {
      abortController.abort();
    };
  }, []);

  return supportsWebp;
}
