import { TIconId } from '@nrk/yr-icons';
import { forwardRef } from 'react';
import { IconButton } from '../IconButton/IconButton';
import './MapIconButton.scss';

interface IMapIconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  iconId: TIconId;
  ariaLabel: string;
  // We are removing children here because in IconButton we have set children to never.
  children?: never;
}

export const MapIconButton = forwardRef<HTMLButtonElement, IMapIconButtonProps>((props, ref) => {
  const { iconId, ariaLabel, ...htmlProps } = props;
  return (
    <IconButton
      className="map-icon-button"
      as="button"
      ref={ref}
      icon={iconId}
      buttonSize="large"
      buttonType="primary"
      hideBorder={true}
      ariaLabel={ariaLabel}
      {...htmlProps}
    />
  );
});
