import { Text } from '../Text/Text';

interface IProps {
  imageSrc: string;
  alt: string;
  label: string;
  href: string;
  selected: boolean;
  onSelected?: () => void;
}

export default function MapTypeSelector__TypeButton(props: IProps) {
  const { imageSrc, alt, label, href, selected, onSelected } = props;
  return (
    <a
      href={href}
      className="map-type-selector__type-button"
      aria-current={selected === true ? 'page' : undefined}
      onClick={onSelected}
    >
      <div className="map-type-selector__type-button-border-container">
        <img className="map-type-selector__type-button-image" alt={alt} src={imageSrc} width={96} height={60}></img>
      </div>
      <Text className="map-type-selector__type-button-title" size="4" weight="bold">
        {label}
      </Text>
    </a>
  );
}
