import { ScaleUnit } from '@nrk/yr-maplibre-gl-js-scale';
import { useEffect, useState } from 'react';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { IMapEvent, YrMap } from '@nrk/yr-map';
import './MapScaleLine.scss';
import { Text } from '../Text/Text';
const MAP_SCALE_WIDTH = 150;

interface IProps {
  map: YrMap;
}

export function MapScaleLine(props: IProps) {
  const { map } = props;

  const translate = useTranslate();

  const [scale, setScale] = useState<{
    ratio: number;
    distance: number;
    unit: ScaleUnit;
  }>();

  useEffect(() => {
    function handleMapEvent(event: IMapEvent) {
      if (event.type === 'map:scale') {
        setScale(event);
      }
    }

    map.addEventListener(handleMapEvent);

    return () => {
      map.removeEventListener(handleMapEvent);
    };
  }, [map]);

  if (scale == null) {
    return null;
  }

  return (
    <div className="map-scale-line">
      <Text as="div" size="5" weight="bold" className="map-scale-line__values">
        <div className="map-scale-line__min-value">0</div>
        <div className="map-scale-line__max-value">
          {scale.distance} {translate(`map/scale/${scale.unit}`)}
        </div>
      </Text>

      <div className="map-scale-line__line" style={{ width: `${scale.ratio * MAP_SCALE_WIDTH}px` }} />
    </div>
  );
}
